@import "./variables.scss";
@import "./fonts.scss";
@import "./animation.scss";
@import "./common.scss";
@import "./scrollbar.scss";

// COMPONENTS
@import "./header.scss";
@import "./footer.scss";
@import "./client_feedback.scss";
@import "./portfolio_article.scss";
@import "./components.scss";

// PAGES
@import "./home.scss";
@import "./podcast.scss";
@import "./portfolio.scss";
