.header {
  display: flex;
  align-items: center;
  padding: 10px 80px;
  position: fixed;
  z-index: 1000;
  width: 100vw;

  @include breakpoint(tablet) {
    padding: 10px 20px;
  }

  @include breakpoint(mobile) {
    padding: 10px 10px;
  }

  &__logo {
    width: 75px;

    @include breakpoint(tablet) {
      width: 50px;
    }

    @include breakpoint(mobile) {
      width: 40px;
    }
  }

  ol {
    display: flex;
    align-items: center;
    margin: 0 auto;

    .nav-item {
      width: 100%;
      min-width: fit-content;
      margin: 0 20px;
      color: $primary-color;
      font-size: 28px;
      padding-bottom: 4px;
      text-align: center;
      border-bottom: 0.5px solid transparent;
      transition: border-bottom 0.8s opacity 0.8s;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }

      @include breakpoint(tablet) {
        font-size: 22px;
        margin: 0 10px;
      }

      @include breakpoint(mobile) {
        font-size: 16px;
        margin: 0 6px;
      }

      &.here,
      &:hover {
        border-bottom: 0.5px solid $primary-color;
      }

      .nav-link {
        font-family: "DosisMedium";
        letter-spacing: 2px;
        color: #8bb6a8;
        padding: 2px 0;
      }
    }
  }

  .instagram-icon,
  .twitch-icon {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }
}

.header.opacity {
  background-color: $bg-black-70;
}
