@-webkit-keyframes slide-right-in {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-right-in {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes pricing-slide-right-in {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes pricing-slide-right-in {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-right-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    opacity: 0;
  }
}
@keyframes slide-right-out {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-left-in {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-left-in {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@-webkit-keyframes header-slide-bottom {
  0% {
    -webkit-transform: translateY($header-offset);
    transform: translateY($header-offset);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes header-slide-bottom {
  0% {
    -webkit-transform: translateY($header-offset);
    transform: translateY($header-offset);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
    transform: rotateX(-360deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
    transform: rotateX(-360deg);
  }
}

.slide-right-in {
  -webkit-animation: slide-right-in 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-right-in 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.pricing-slide-right-in-1 {
  -webkit-animation: pricing-slide-right-in 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: pricing-slide-right-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.pricing-slide-right-in-2 {
  -webkit-animation: pricing-slide-right-in 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: pricing-slide-right-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation-delay: 0.4s;
}

.pricing-slide-right-in-3 {
  -webkit-animation: pricing-slide-right-in 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: pricing-slide-right-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation-delay: 0.8s;
}

.pricing-slide-right-in-4 {
  -webkit-animation: pricing-slide-right-in 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: pricing-slide-right-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation-delay: 1.2s;
}

.pricing-slide-right-in-5 {
  -webkit-animation: pricing-slide-right-in 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: pricing-slide-right-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation-delay: 1.6s;
}

.pricing-slide-right-in-6 {
  -webkit-animation: pricing-slide-right-in 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: pricing-slide-right-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation-delay: 2s;
}

.slide-right-out {
  -webkit-animation: slide-right-out 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-right-out 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-left-in {
  -webkit-animation: slide-left-in 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-left-in 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.bounce-top {
  -webkit-animation: bounce-top 2s infinite both;
  animation: bounce-top 2s infinite both;
}

.header-slide-bottom {
  -webkit-animation: header-slide-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: header-slide-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.flip-horizontal-bottom {
  -webkit-animation: flip-horizontal-bottom 1s
    cubic-bezier(0.075, 0.82, 0.165, 1) both;
  animation: flip-horizontal-bottom 1s cubic-bezier(0.075, 0.82, 0.165, 1)
    both;
}
