html {
  height: 100vh;
}

body.main {
  height: 500vh;

  .header {
    transition: all 1s;

    @include breakpoint(tablet) {
      top: 0;
    }
  }
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 50vh;
    z-index: 5;

    @include breakpoint(tablet) {
      top: 20vh;
    }

    .first-header,
    .first-subheader {
      color: white;
      font-family: "ArapeyRegular";
      margin-left: 50px;

      @include breakpoint(mobile) {
        margin-left: 25px;
      }
    }

    .first-header {
      font-size: 66px;
      letter-spacing: 7px;
      top: 50vh;

      @include breakpoint(tablet) {
        font-size: 55px;
      }

      @include breakpoint(mobile) {
        font-size: 40px;
      }
    }

    .first-subheader {
      font-size: 26px;
      letter-spacing: 4px;

      @include breakpoint(mobile) {
        font-size: 16px;
      }
    }
  }

  &__second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 200vh;
    z-index: 5;
    visibility: hidden;
    width: 100%;
    margin-left: 13px;

    @include breakpoint(laptop) {
      margin-left: 0;
    }

    .second-header,
    .second-subheader {
      color: white;
      font-family: "ArapeyRegular";

      @include breakpoint(tablet) {
        text-align: center;
      }
    }

    .second-header {
      font-size: 100px;
      letter-spacing: 7px;
      margin-bottom: 6px;

      @include breakpoint(tablet) {
        font-size: 58px;
      }

      @include breakpoint(mobile) {
        font-size: 60px;
      }
    }

    .second-subheader {
      font-size: 31px;
      letter-spacing: 4px;

      @include breakpoint(tablet) {
        font-size: 24px;
      }

      @include breakpoint(mobile) {
        font-size: 22px;
      }
    }
  }

  &__third {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 400vh;
    z-index: 5;
    visibility: hidden;
    width: 100%;
    margin-left: 13px;

    button.btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 4px;
      border-color: #fff;
      border-radius: 6px;

      &:hover {
        background-color: #f8f9fa2e;
      }

      .second-header,
      .second-subheader {
        color: white;
        font-family: "ArapeyRegular";

        @include breakpoint(tablet) {
          text-align: center;
        }
      }

      .second-header {
        font-size: 100px;
        letter-spacing: 7px;
        margin-bottom: -27px;

        @include breakpoint(tablet) {
          font-size: 58px;
        }

        @include breakpoint(mobile) {
          font-size: 60px;
        }
      }

      .second-subheader {
        font-size: 31px;
        letter-spacing: 4px;

        @include breakpoint(tablet) {
          font-size: 24px;
        }

        @include breakpoint(mobile) {
          font-size: 22px;
        }
      }
    }
  }

  .scroll-icon {
    width: 60px;
    z-index: 500;
    position: fixed;
    bottom: 40px;
    left: 49.6vw;
    transition: all 1s;
    z-index: 3;
  }

  .visible {
    visibility: visible;
  }

  .invisible {
    opacity: 0;
  }

  .position-fixed {
    position: fixed;
  }

  .top-50vh {
    top: 50vh;
  }

  .top-47vh {
    top: 47vh;
  }

  .transition-top {
    transition: top 1.5s;
  }

  .modal {
    .modal-dialog {
      max-width: none;
      display: flex;
      justify-content: center;
      align-items: center;

      .modal-content {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        width: 50vw;

        @include breakpoint(untilDesktop) {
          width: 70vw;
        }

        @include breakpoint(tablet) {
          width: 85vw;
        }

        .modal-body {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 0;

          .welcome-video {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 60vh;

            iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

canvas {
  position: fixed;
  max-width: 100vw;
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  object-fit: cover;
  image-rendering: auto;
  filter: brightness(0.8);
  transform: scale(1.2) translateX(-30px);

  @include breakpoint(laptop) {
    transform: scale(1.2) translateX(-38px);
  }

  @include breakpoint(mobile) {
    transform: scale(1.2) translateX(-42px);
  }
}
