.spacing {
  letter-spacing: 1.5px;
}

.title {
  color: $primary-color;
  font-size: 48px;
  font-family: "DosisSemiBold";
  text-transform: uppercase;
  margin-bottom: 20px;
}

button.btn {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: "CodeProMedium";
  border-radius: 40px;
  margin-top: auto;
  padding: 10px 24px;
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.6));
  transition: all 0.4s;

  &:hover {
    filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.8));
  }

  .whatsapp-icon {
    width: 26px;
    margin-right: 20px;
    filter: brightness(5);

    @include breakpoint(tablet) {
      width: 22px;
    }
  }
}

.linkable {
  cursor: pointer;
  transition: all 0.6s;

  &:hover {
    filter: brightness(4);
  }
}

.whatsapp-fab {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.6));
  transition: all 0.2s;

  &:hover {
    transform: translateX(0.5px) translateY(-0.5px);
  }

  @include breakpoint(tablet) {
    width: 50px;
    height: 50px;
    bottom: 28px;
    right: 32px;
  }

  @include breakpoint(mobile) {
    width: 45px;
    height: 45px;
    bottom: 20px;
    right: 26px;
  }

  img {
    filter: brightness(5);
    width: 44px;
    height: 44px;
    margin-left: 1px;

    @include breakpoint(tablet) {
      width: 40px;
      height: 40px;
    }

    @include breakpoint(mobile) {
      width: 36px;
      height: 36px;
    }
  }
}
