.portfolio-article {
  display: flex;
  flex-direction: row;
  padding: 100px 70px;

  @include breakpoint(laptop) {
    flex-direction: column;
    align-items: center;
    padding: 100px 50px;
  }

  @include breakpoint(mobile) {
    padding: 50px 20px;
  }

  &--video-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    max-width: 1000px;
    margin-right: 40px;

    @include breakpoint(laptop) {
      width: 100%;
      margin-right: 0;
    }

    .carousel {
      width: 100%;
      display: flex;
      align-items: center;

      @include breakpoint(tablet) {
        width: 100%;
      }

      .carousel-inner {
        width: 75%;
        border-radius: 8px;
        margin: 0 auto;

        iframe {
          height: 500px;

          @include breakpoint(tablet) {
            height: 400px;
          }

          @include breakpoint(mobile) {
            height: 300px;
          }
        }
      }

      button.white {
        filter: brightness(1);
      }

      button.black {
        filter: brightness(0);
      }
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;

    @include breakpoint(laptop) {
      width: 90%;
      margin-top: 50px;
      align-items: center;
    }

    .content {
      &--title {
        font-size: 40px;
        font-family: "DosisSemiBold";
        text-transform: uppercase;
        margin-bottom: 20px;
        text-align: center;
      }

      &--text {
        font-size: 24px;
        font-family: "AlegreyaRegular";
        margin-bottom: 130px;
        text-align: justify;

        @include breakpoint(mobile) {
          font-size: 20px;
          text-align: center;
          margin-bottom: 60px;
        }
      }
    }

    a {
      margin: auto auto 0 auto;

      button.btn {
        min-width: max-content;

        @include breakpoint(tablet) {
          min-width: auto;
          font-size: 22px;
        }

        @include breakpoint(mobile) {
          padding: 10px 20px;
          font-size: 100%;
        }
      }
    }
  }
}