.portfolio {
  &__bg {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-image: url("../img/Estudio Sawi ABR 2022-09.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      filter: blur(3px) brightness(0.7);
    }

    &--text {
      position: absolute;
      font-size: 100px;
      color: $primary-color;
      border-bottom: 2px solid $primary-color;
      text-transform: uppercase;

      @include breakpoint(mobile) {
        font-size: 70px;
      }
    }
  }

  &__video {
    background-color: #fff;

    &--content {
      .content {
        &--title,
        &--text {
          color: #000;
        }
      }
    }
  }

  &__live {
    background-color: $bg-black;

    &--content {
      .content {
        &--title,
        &--text {
          color: #fff;
        }
      }
    }
  }

  &__show {
    background-color: $bg-primary;

    &--content {
      .content {
        &--title,
        &--text {
          color: #fff;
        }
      }
    }
  }
}
