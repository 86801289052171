body::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 20px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba($bg-grey, 0.7);
  border-radius: 20px;

  &:hover {
    background-color: rgba($bg-grey, 0.9);
  }
}
