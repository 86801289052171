.podcast {
  &__bg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    filter: brightness(0.8);

    &--text {
      font-size: 100px;
      color: $primary-color;
      border-bottom: 2px solid $primary-color;
      text-transform: uppercase;
      text-align: center;
      z-index: 100;

      @include breakpoint(laptop) {
        font-size: 80px;
      }
      @include breakpoint(tablet) {
        width: min-content;
      }

      @include breakpoint(mobile) {
        font-size: 70px;
      }
    }

    .carousel {
      position: absolute;
      height: 100vh;

      .carousel-item {
        filter: blur(3px) brightness(0.7);

        img {
          width: 100vw;
          height: 100vh;
          object-fit: cover;
          object-position: top;
        }
      }

      .carousel-indicators {
        margin-bottom: 40px;

        button {
          width: 90px;
          height: 5px;
          border: none;
          border-radius: 8px;
          margin: 0 10px;
        }
      }
    }
  }

  &__video {
    background-color: $bg-primary;

    &--content {
      .content {
        &--title,
        &--text {
          color: #fff;
        }
      }
    }
  }

  &__about {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 180px 120px;

    @include breakpoint(untilDesktop) {
      padding: 180px 40px;
    }

    @include breakpoint(laptop) {
      flex-direction: column;
      padding: 100px 40px;
    }

    @include breakpoint(mobile) {
      padding: 100px 15px;
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 46%;
      margin-right: 30px;

      @include breakpoint(laptop) {
        width: 90%;
        align-items: center;
        margin-right: 0;
        margin-bottom: 30px;
      }

      .about-title {
        visibility: hidden;
      }

      .about-text {
        font-size: 22px;
        font-family: "ArapeyRegular";
        line-height: 24px;
        hyphens: auto;
        text-align: justify;
        visibility: hidden;
      }
    }

    &--video {
      display: flex;
      justify-content: center;
      width: 345px;
      visibility: hidden;

      @include breakpoint(mobile) {
        width: 100%;
      }

      iframe {
        width: 100%;
        border-radius: 8px;

        @include breakpoint(tablet) {
          height: 470px;
        }
      }
    }
  }

  &__know-us {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: $bg-primary;
    padding: 100px 70px;

    @include breakpoint(laptop) {
      flex-direction: column;
      align-items: center;
    }

    @include breakpoint(tablet) {
      padding: 80px 40px;
    }

    @include breakpoint(mobile) {
      padding: 50px 20px;
    }

    &--img-carousel {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      max-width: 800px;
      margin-right: 40px;

      @include breakpoint(laptop) {
        width: 100%;
        margin-right: 0;
      }

      .carousel-inner {
        width: 75%;
        border-radius: 8px;
        margin: 0 auto;

        .carousel-item {
          height: 430px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include breakpoint(tablet) {
            height: 100%;
          }

          img {
            border-radius: 8px;
          }
        }

        button {
          filter: brightness(0);
        }
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 40%;

      @include breakpoint(laptop) {
        width: 90%;
        margin-top: 50px;
        align-items: center;
      }

      .know-us {
        &--title {
          font-size: 40px;
          font-family: "DosisSemiBold";
          text-transform: uppercase;
          margin-bottom: 20px;
          text-align: center;
        }

        &--text {
          font-size: 24px;
          font-family: "ArapeyRegular";
          margin-bottom: 130px;
          text-align: justify;

          @include breakpoint(mobile) {
            font-size: 20px;
            text-align: center;
            margin-bottom: 60px;
          }
        }
      }

      a {
        margin: auto auto 0 auto;

        button {
          min-width: max-content;

          @include breakpoint(tablet) {
            min-width: auto;
            font-size: 22px;
          }

          @include breakpoint(mobile) {
            padding: 10px 20px;
            font-size: 100%;
          }
        }
      }
    }
  }

  &__features {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 100px 20px;

    &--header {
      font-size: 50px;
      font-family: "DosisMedium";
      color: black;
      text-transform: uppercase;
      margin-bottom: 70px;
    }

    &--icons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 125px;
        margin: 0 40px 40px 40px;

        @include breakpoint(tablet) {
          margin: 0 20px 40px 20px;
        }

        img {
          width: 100px;
          height: 100px;

          @include breakpoint(tablet) {
            width: 70px;
            height: 70px;
          }

          @include breakpoint(mobile) {
            width: 50px;
            height: 50px;
          }
        }

        h3 {
          color: black;
          font-size: 26px;
          font-family: "DosisBold";
          text-align: center;
          margin-top: 15px;
        }
      }
    }
  }

  &__pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 20px;

    @include breakpoint(laptop) {
      padding: 60px 20px;
    }

    &--header {
      font-size: 50px;
      font-family: "DosisLight";
      color: white;
      text-transform: uppercase;
      margin-bottom: 70px;
      text-align: center;
    }

    &--cards {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      margin: 150px 0 100px 0;

      @include breakpoint(laptop) {
        flex-direction: column;
        align-items: center;
        margin: 80px 0 40px 0;
      }

      .pricing-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 35%;
        background-color: $bg-primary;
        color: white;
        border-radius: 20px;
        font-family: "CodeProMedium";

        @include breakpoint(untilDesktop) {
          width: 45%;
        }

        @include breakpoint(laptop) {
          width: 90%;
          margin-bottom: 50px;
        }

        &__header {
          text-transform: uppercase;
          font-size: 38px;
          font-family: "DosisBold";
          margin-top: 20px;

          @include breakpoint(tablet) {
            font-size: 32px;
          }

          @include breakpoint(mobile) {
            font-size: 28px;
          }
        }

        &__subheader {
          font-size: 28px;
          margin-top: 10px;
          margin-bottom: 40px;

          @include breakpoint(mobile) {
            font-size: 22px;
            margin-bottom: 15px;
          }
        }

        &__content {
          height: 100%;
          margin: 0 70px 20px 70px;

          .pricing-list {
            font-size: 30px;
            list-style: disc;

            @include breakpoint(tablet) {
              font-size: 24px;
            }

            @include breakpoint(mobile) {
              font-size: 20px;
            }

            .pricing-items-left,
            .pricing-items-right {
              margin-top: 10px;
              opacity: 0;
            }
          }
        }

        &__footer {
          background-color: white;
          color: $primary-color;
          margin-top: auto;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          margin-top: 20px;
          text-align: center;

          a {
            font-size: 38px;
            font-family: "CodeProBold";
            letter-spacing: 3px;
            transition: all 0.2s;

            @include breakpoint(tablet) {
              font-size: 30px;
            }

            @include breakpoint(mobile) {
              font-size: 24px;
            }

            &:hover {
              color: inherit;
              text-decoration: underline;
              transform: scale(1.01);
            }
          }
        }
      }
    }
  }

  .display {
    visibility: visible;
  }
}
