@import "../../node_modules/bootstrap/scss/bootstrap";

// BREAKPOINTS
@mixin breakpoint($breakpoint) {
  @if $breakpoint== "desktop" {
    @media only screen and (min-width: 1360px) {
      @content;
    }
  }
  @if $breakpoint== "untilDesktop" {
    @media only screen and (max-width: 1359px) {
      @content;
    }
  }
  @if $breakpoint== "laptop" {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint== "tablet" {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint== "mobile" {
    @media only screen and (max-width: 425px) {
      @content;
    }
  }
}

// RESET
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: "DosisRegular";
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  display: flex;
  flex-direction: column;
  background-color: $bg-black;
  color: white;
  overflow-x: hidden;
  line-height: 1;
}
* {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: unset;
  text-decoration: none;
  &:visited {
    color: unset;
    text-decoration: none;
  }
}

input,
textarea {
  outline: none;
  color: unset;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

i,
span {
  user-select: none; /* Non-prefixed version, currently
 -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
