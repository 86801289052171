.client-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 200px 20px;

  &--header {
    color: $primary-color;
    text-align: center;
    margin-top: 80px;
  }

  &--carousel {
    &.desktop {
      @include breakpoint(laptop) {
        display: none;
      }
    }
    &.mobile {
      display: none;

      @include breakpoint(laptop) {
        display: flex;
      }
    }

    .carousel-item {
      &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    .feedback-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;

      @include breakpoint(untilDesktop) {
        width: 35%;
      }

      @include breakpoint(laptop) {
        width: 85%;
      }

      @include breakpoint(tablet) {
        width: 95%;
      }

      .profile-link {
        position: relative;
        top: 70px;
        z-index: 1;

        img {
          border-radius: 50%;
          border: 8px solid $bg-black;
          transition: all 0.4s;
          width: 155px;
          height: 156px;
          object-fit: cover;
          
          &.position-top {
            object-position: top;
          }
        }

        &:hover img {
          transform: translateX(1px) translateY(1px);
        }
      }

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
        height: 500px;
        background-color: $bg-primary;
        color: white;
        padding: 30px;
        border-radius: 20px;

        @include breakpoint(tablet) {
          width: 95%;
        }

        &__name {
          font-size: 32px;
          font-family: "DosisBold";
          margin-top: 60px;
        }

        &__media {
          font-size: 22px;
          font-family: "AlegreyaRegular";
          text-align: center;
          margin-top: 10px;

          a {
            transition: all 0.6s;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &__text {
          height: 100%;
          font-size: 26px;
          font-family: "AlegreyaRegular";
          text-align: center;
          margin: 35px 0 50px 0;
          overflow-y: auto;

          @include breakpoint(tablet) {
            font-size: 22px;
          }

          &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
            border-radius: 20px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border-radius: 20px;
            transition: all 0.8s;

            @include breakpoint(mobile) {
              background-color: rgba($bg-grey, 0.8);
            }
          }

          &:hover::-webkit-scrollbar-thumb {
            background-color: rgba($bg-grey, 0.8);
          }
        }
      }
    }

    .carousel-indicators {
      bottom: -80px;

      &.mobile {
        margin-left: 5%;
        margin-right: 5%;
      }

      .indicatiors-btn {
        width: 90px;
        height: 10px;
        background-color: $bg-primary;
        border: none;
        border-radius: 8px;
        margin: 0 10px;

        @include breakpoint(mobile) {
          margin: 0 5px;
          height: 14px;
        }
      }
    }
  }
}
