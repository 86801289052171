.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: $bg-grey;
  padding: 100px 0;

  @include breakpoint(tablet) {
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
  }

  &__contact {
    &--content {
      display: flex;
      flex-direction: column;
      width: 40%;
      color: white;

      @include breakpoint(tablet) {
        width: 90%;
        align-items: center;
      }

      .content-header {
        font-size: 38px;
        font-family: "DosisBold";
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      .content-info {
        font-size: 22px;
        font-family: "CodeProMediumItalic";
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      .content-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 50%;

        @include breakpoint(tablet) {
          width: 80%;
          max-width: 350px;
        }

        .media-icon {
          margin-top: 30px;
          margin-left: 10px;
          margin-right: 10px;

          @include breakpoint(mobile) {
            margin-left: 4px;
            margin-right: 4px;
          }

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    &--map {
      width: 45%;
      height: 220px;
      border-radius: 20px;
      background-color: white;

      @include breakpoint(tablet) {
        width: 80%;
        margin-top: 40px;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__rights {
    padding: 100px 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(tablet) {
      padding: 80px 30px;
    }

    @include breakpoint(mobile) {
      padding: 60px 20px;
    }

    &--text {
      text-transform: uppercase;
      font-size: 24px;
      font-family: "CodeProMediumItalic";
      text-align: center;
    }
  }
}
