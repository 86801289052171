@font-face {
  font-family: DosisBold;
  src: url(../fonts/Dosis-Bold.ttf);
}
@font-face {
  font-family: DosisLight;
  src: url(../fonts/Dosis-Light.ttf);
}
@font-face {
  font-family: DosisMedium;
  src: url(../fonts/Dosis-Medium.ttf);
}
@font-face {
  font-family: DosisRegular;
  src: url(../fonts/Dosis-Regular.ttf);
}
@font-face {
  font-family: DosisSemiBold;
  src: url(../fonts/Dosis-SemiBold.ttf);
}
@font-face {
  font-family: CodeProBold;
  src: url(../fonts/SourceCodePro-Bold.ttf);
}
@font-face {
  font-family: CodeProMedium;
  src: url(../fonts/SourceCodePro-Medium.ttf);
}
@font-face {
  font-family: CodeProMediumItalic;
  src: url(../fonts/SourceCodePro-MediumItalic.ttf);
}
@font-face {
  font-family: CodeProRegular;
  src: url(../fonts/SourceCodePro-Regular.ttf);
}
@font-face {
  font-family: AlegreyaRegular;
  src: url(../fonts/Alegreya-Regular.ttf);
}
@font-face {
  font-family: ArapeyRegular;
  src: url(../fonts/Arapey-Regular.ttf);
}
